import { Application } from "@splinetool/runtime";

console.log(
  `%c
  ░▒▓█████▓░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█▒▒█▒░░░░░░░░░░░░░░░░▓█████▓░░░░▒▓████▓▒░░░░░▓████▓▒░
  ▒█▓░░░░░▓█▒░░▒█▒░░░░░░░░░░░░░░░░░░░░▒█▒░░░░░░░░░░░░░░░░░░██░░░░░██░░▓█▓░░░░▒██░░▒█▓░░░░▒█▓
  ▒█▓░░░░░░░░▒██████░▓█░░░░░▓▓░░▒▓█▓▓▓▓█▒░█▒░░▒▓█▓█▓▒░░░░░░▒░░░░░░▓█▒▒██░░░░░░▓█▓░██░░▒▒▒░░░
  ░▒▓███▓▓▒░░░░▒█▒░░░██░░░░░██░▓█▒░░░░██▒▒█▒░██░░░░░██░░░░░░░░░░▒██▒░▓█▓░░░░░░▒██░███▓▒▒▓██░
  ░░░░░░░▒▓█▓░░▒█▒░░░██░░░░░██░█▓░░░░░▒█▒▒█▒▓█▒░░░░░▒█▓░░░░░░▒▓█▓▒░░░▒█▓░░░░░░▒█▓░██░░░░░░██
  ▓█▒░░░░░░██░░▒█▒░░░██░░░░░██░██░░░░░▓█▒▒█▒▒█▓░░░░░▒█▒░░░░▒██▒░░░░░░░██░░░░░░██░░▓█░░░░░░██
  ░▓█▓▓▒▒▓██▒░░░██▒▓░▒██▒▒▒▓██░░██▒▒▒▓██▒▒█▒░▒█▓▒▒▒▓█▒░░░░░██▓▓▓▓▓▓▓▒░░▓█▓▒▒▓██░░░░▓█▓▒▒▓██░  
`,
  "font-family:monospace; color: #695CFF;"
);
console.log(
  `%c
We build beatiful digital products
Get in touch: hello@studio206.dev
`,
  "font-family:monospace; color: #695CFF; font-weight: bold;"
);

if (module.hot) {
  module.hot.accept();
}

const canvas = document.getElementById("canvas3d");
const app = new Application(canvas);
app
  .load("https://prod.spline.design/FiyK3Jqy9hBzjaiM/scene.splinecode")
  .then(() => {
    const width = window.innerWidth;
    width <= 600 && app.setZoom(0.3);
    width > 600 && width <= 768 && app.setZoom(0.5);
  });
